import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { SingleSelectForm } from './singleSelectForm'
import {
  buttons,
  formGroup,
  nextButton,
  singleSelectForms,
  toTopButton
} from './commonCss'

export type RewardFormData = {
  monthlyReward: string | null
  hourlyReward: string | null
}

type Props = {
  onSubmit: (data: RewardFormData) => void
  onClose: () => void
  onBack: () => void
  disabled?: boolean
  initialData?: {
    monthlyReward?: string | null
    hourlyReward?: string | null
  }
}

const monthlyRewards = [
  '10〜30万円 / 月',
  '40〜60万円 / 月',
  '70〜100万円 / 月',
  '150万円以上 / 月'
]
const hourlyRewards = [
  '3,000円 / 時間',
  '4,000〜6,000円 / 時間',
  '7,000〜9,000円 / 時間',
  '10,000〜12,000円 / 時間',
  '13,000〜15,000円 / 時間',
  '16,000円以上 / 時間'
]

export const RewardForm = (props: Props) => {
  const { control, handleSubmit, watch } = useForm<RewardFormData>({
    defaultValues: {
      monthlyReward: props.initialData?.monthlyReward,
      hourlyReward: props.initialData?.hourlyReward
    }
  })

  const onSubmit = (data: RewardFormData) => {
    props.onSubmit(data)
  }
  const selectedMonthlyReward = watch('monthlyReward')
  const selectedHourlyReward = watch('hourlyReward')

  return (
    <form css={formGroup} onSubmit={handleSubmit(onSubmit)}>
      <div css={singleSelectForms}>
        <Controller
          name="monthlyReward"
          control={control}
          render={({ field }) => {
            return (
              <SingleSelectForm
                question="ご希望の報酬月額は？"
                selectedOption={field.value}
                options={monthlyRewards}
                onOptionClick={field.onChange}
              />
            )
          }}
        />
        <Controller
          name="hourlyReward"
          control={control}
          render={({ field }) => {
            return (
              <SingleSelectForm
                question="ご希望の報酬単価は？"
                selectedOption={field.value}
                options={hourlyRewards}
                onOptionClick={field.onChange}
              />
            )
          }}
        />
      </div>
      <div css={buttons}>
        <button
          css={nextButton}
          disabled={
            props.disabled || !selectedMonthlyReward || !selectedHourlyReward
          }
        >
          次へ
        </button>
        <button css={toTopButton} type="button" onClick={props.onBack}>
          戻る
        </button>
      </div>
    </form>
  )
}
