import React from 'react'
import { css } from '@emotion/react'
import { mq } from '@/components/media/media'
import Slider, { Settings } from 'react-slick'
import { Voice } from '@/constants/scalehack/voiceList'
import color from '@/components/lp/scalehack/color'

type Props = {
  className?: string
  voices: Voice[]
}

const settings: Settings = {
  infinite: false,
  speed: 750,
  slidesToShow: 1,
  autoplay: false,
  swipeToSlide: true,
  arrows: false,
  adaptiveHeight: true,
  responsive: [
    {
      // NOTE: 1200px以上はSlickを無効にしたいので、絶対に越えないような値を設定
      breakpoint: 10000,
      settings: 'unslick'
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 1
      }
    }
  ]
}

export const VoiceSlide = (props: Props) => {
  const { className, voices } = props

  return (
    <Slider {...settings} css={slider} className={className}>
      {voices.map((voice, i) => {
        return (
          <div key={i} css={slide}>
            <div css={slideInner}>
              <div css={people}>
                <div css={icon}>
                  <img
                    css={iconImg}
                    src={voice.icon}
                    alt=""
                    width={200}
                    height={200}
                  />
                </div>
                <div css={caption}>{voice.caption}</div>
              </div>
              <p css={text}>{voice.text}</p>
            </div>
          </div>
        )
      })}
    </Slider>
  )
}

const slider = css`
  .slick-list {
    ${mq.midSHM} {
      overflow: visible;
    }
  }
`

const slide = css`
  margin-bottom: 32px;

  ${mq.midSHM} {
    padding: 0 10px;
    margin-bottom: 0;
  }
`

const slideInner = css`
  display: flex;
  gap: 0 15px;
  align-items: center;

  ${mq.midSHM} {
    display: block;
  }
`

const people = css`
  flex-shrink: 0;
  width: 70px;
  text-align: center;

  ${mq.midSHM} {
    width: auto;
  }
`

const icon = css`
  width: 56px;
  height: 56px;
  margin: 0 auto;
`

const iconImg = css`
  height: auto;
`

const caption = css`
  margin-top: 5px;
  font-size: 1.2rem;
  font-weight: 600;
`

const text = css`
  flex: 1 1 auto;
  padding: 16px;
  font-size: 1.4rem;
  line-height: 1.5;
  text-align: justify;
  border: 1px dotted ${color.gray76};
  border-radius: 12px;

  ${mq.midSHM} {
    padding: 16px 16px 40px;
    margin-top: 16px;
  }
`
