import React from 'react'
import { useForm } from 'react-hook-form'
import { buttons, formGroup, nextButton, toTopButton } from './commonCss'
import { css } from '@emotion/react'
import color from '@/components/lp/scalehack/color'
import { mq } from '@/components/media/media'

export type ContactFormData = {
  email: string
  phone: string
}

type Props = {
  onSubmit: (data: ContactFormData) => void
  onClose: () => void
  onBack: () => void
  disabled?: boolean
  initialData?: {
    email?: string | null
    phone?: string | null
  }
}

export const ContactForm = (props: Props) => {
  const { register, handleSubmit, formState } = useForm<ContactFormData>({
    defaultValues: {
      email: props.initialData?.email ?? '',
      phone: props.initialData?.phone ?? ''
    },
    mode: 'onChange'
  })

  const onSubmit = (data: ContactFormData) => {
    props.onSubmit(data)
  }

  return (
    <form css={formGroup} onSubmit={handleSubmit(onSubmit)}>
      <div css={forms}>
        <label css={label}>
          <span css={labelTitle}>メールアドレス</span>
          <input
            type="email"
            css={formInput}
            placeholder="例）xxx@gmail.com"
            {...register('email', {
              required: '必須項目に入力してください。',
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: '入力されたメールアドレスに間違いがあります。'
              }
            })}
          />
        </label>
        <label css={label}>
          <span css={labelTitle}>電話番号</span>
          <input
            type="tel"
            css={formInput}
            placeholder="例）08012345678"
            {...register('phone', {
              required: '必須項目に入力してください。'
            })}
          />
        </label>
      </div>
      <div css={buttons}>
        <button
          css={nextButton}
          disabled={!formState.isValid || props.disabled}
        >
          高額案件を獲得する
        </button>
        <button css={toTopButton} type="button" onClick={props.onBack}>
          戻る
        </button>
      </div>
    </form>
  )
}

const forms = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 40px;
  color: color.brack333;
  border: 1px dashed #333333;
  border-radius: 12px;
  ${mq.midSHM} {
    flex-direction: column;
    width: 100%;
    padding: 16px;
  }
`

const formInput = css`
  gap: 10px;

  width: 180px;
  max-width: 180px;
  padding: 12px 8px;

  font-size: 1.6rem;
  line-height: 18px;
  background-color: ${color.white};

  border: 1px solid #acacac;
  border-radius: 4px;

  ::placeholder {
    opacity: 0.4;
  }
  ${mq.midSHM} {
    flex: 1;
    max-width: 100%;
    margin-right: 20px;
  }
`

const label = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

const labelTitle = css`
  width: 96px;
  padding-top: 9px;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 18px;

  color: #333333;

  ${mq.midSHM} {
    width: 118px;
    font-size: 1.4rem;
    line-height: 21px;
  }
`
