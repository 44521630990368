import React from 'react'
import { css } from '@emotion/react'
import color from '@/components/lp/scalehack/color'
import { mq } from '@/components/media/media'

type Option = {
  name: string
  src: string
}

type Props = {
  options: Option[]
  selectedOptions: string[]
  onOptionClick: (name: string) => void
}

export const MultiSelectForm = (props: Props) => {
  return (
    <section css={section}>
      <div css={optionItemsRow}>
        <div css={optionItems}>
          {Array.from(
            { length: Math.ceil(props.options.length / 2) },
            (_, i) => {
              const firstItem = props.options[i * 2]
              return (
                <button
                  css={optionItem(
                    props.selectedOptions.includes(firstItem.name)
                  )}
                  key={`${firstItem.name}`}
                  type="button"
                  onClick={() => props.onOptionClick(firstItem.name)}
                >
                  <img src={firstItem.src} alt="" />
                  {firstItem.name}
                </button>
              )
            }
          )}
        </div>
        <div css={optionItems}>
          {Array.from(
            { length: Math.ceil(props.options.length / 2) },
            (_, i) => {
              const firstItem = props.options[i * 2 + 1]
              if (!firstItem) return null
              return (
                <button
                  css={optionItem(
                    props.selectedOptions.includes(firstItem.name)
                  )}
                  key={`${firstItem.name}`}
                  type="button"
                  onClick={() => props.onOptionClick(firstItem.name)}
                >
                  <img src={firstItem.src} alt="" />
                  {firstItem.name}
                </button>
              )
            }
          )}
        </div>
      </div>
      <p>{'複数回答可 (必須)'} </p>
    </section>
  )
}

const section = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: calc(100vw - 40px);
  max-width: 412px;
  color: color.brack333;

  p {
    font-size: 1.1rem;
    font-weight: 600;
  }

  ${mq.midSHM} {
    gap: 12px;
  }
`

const optionItems = css`

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 100%;
  ${mq.midSHM} {
    gap: 16px;
  }
`

const optionItemsRow = css`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 12px;
  height: 100%;
  ${mq.midSHM} {
    gap: 20px;
  }
`

const optionItem = (isSelected: boolean) => css`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  height: 38px;
  padding: 8px;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 18px;
  color: ${isSelected ? color.white : color.brack333};

  background: ${isSelected ? color.red1 : color.white};
  border: 1px solid ${isSelected ? color.red1 : '#acacac'};
  border-radius: 4px;
  img {
    width: 22px;
    height: 22px;
  }

  &:hover {
    color: ${isSelected ? color.white : color.red1};
    background: ${isSelected ? color.red1 : color.white};
    border: 1px solid ${isSelected ? color.red1 : color.red1};
  }
  ${mq.midSHM} {
    font-size: 1.1rem;
    line-height: 1.2;
  }
`
