import React from 'react'
import { css } from '@emotion/react'
import color from '@/components/lp/scalehack/color'

type Props = {
  stepNum: number
  currentStep: number
}

export const FormSteps = (props: Props) => {
  if (props.stepNum < props.currentStep) return <></>
  return (
    <section css={section}>
      <div css={line}></div>
      {Array.from({ length: props.stepNum }).map((_, i) => {
        return (
          <div css={stepContainer} key={i}>
            <p css={stepText}>STEP</p>
            <p css={stepNum(i + 1 == props.currentStep)}>
              {('00' + (i + 1)).slice(-2)}
            </p>
          </div>
        )
      })}
    </section>
  )
}

const section = css`
  position: relative;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  width: 282px;
  padding: 0px;
  font-family: din-2014, sans-serif;
`

const line = css`
  position: absolute;
  right: 5px;
  bottom: 8px;
  left: 5px;
  height: 0px;

  border: 1px solid #b0b0b0;
`

const stepContainer = css`
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const stepText = css`
  z-index: 1;
  font-size: 1rem;
  font-weight: 700;
  line-height: 13px;
`

const stepNum = (isActive: boolean) => css`
  z-index: 1;
  width: 18px;
  height: 18px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 18px;
  color: ${color.white};
  background: ${isActive ? color.red1 : '#B0B0B0'};
  border-radius: 50%;
`
