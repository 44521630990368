import React from 'react'
import male30 from '@/images/scalehack/for-marketing/freelance/voice_male_30.svg'
import male20 from '@/images/scalehack/for-marketing/freelance/voice_male_20.svg'
import female20 from '@/images/scalehack/for-marketing/freelance/voice_female_20.svg'

export type Voice = {
  icon: string
  caption: string
  text: JSX.Element
}

export const VoiceList: Voice[] = [
  {
    icon: male30,
    caption: '30代／男性',
    text: (
      <>
        自由な働き方を求めてフリーランスとして活動していましたが、
        <b>個人のキャパシティ内に限定された稼働が多く、成長の機会を得づらい</b>
        ことが課題でした。
        <br />
        Scalehack for Marketingでは
        <b>優秀なハイクラス人材の方とチームとして稼働</b>
        することができ、その中での
        <b>
          新たな刺激やフィードバックをもらうことで、成長の機会につながっている
        </b>
        と感じます。
      </>
    )
  },
  {
    icon: female20,
    caption: '20代／女性',
    text: (
      <>
        ライフスタイルの変化に伴い働き方を変えようと思い、
        <b>フリーランスへの転身</b>を考えていましたが、
        <b>初めてのことなので不安がいっぱい</b>でした。
        <br />
        Scalehack for Marketingでは登録時に
        <b>丁寧な面談やスキルのアセスメント</b>を行っていただき、
        <b>自分の力を十分に発揮</b>
        できる案件をご紹介いただけました。稼働中の現在も
        <b>定期的にフォローアップ</b>
        いただいているので、安心しながら働いています。
      </>
    )
  },
  {
    icon: male20,
    caption: '20代／男性',
    text: (
      <>
        今までフリーランスの案件を他社サービスで紹介いただいていましたが、正直なところ
        <b>小さな規模感の仕事</b>が多く、
        <b>自分のキャリア強化につながっていない焦り</b>を感じていました。
        <br />
        Scalehack for Marketingでは独自のルート経由で、
        <b>大企業や大手スタートアップの案件</b>をご紹介いただけているので、
        <b>自分の経歴にプラスになる働き方</b>ができていると感じます。
      </>
    )
  }
]
