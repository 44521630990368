import React from 'react'
import { css } from '@emotion/react'
import color from '@/components/lp/scalehack/color'
import { mq } from '@/components/media/media'

type Props = {
  question: string
  options: string[]
  selectedOption?: string | null
  onOptionClick: (name: string) => void
}

export const SingleSelectForm = (props: Props) => {
  return (
    <section css={section}>
      <div css={form}>
        <h3>{props.question}</h3>
        <div css={optionItems}>
          {props.options.map((option) => {
            return (
              <div
                css={radioButtonWithText}
                key={`${props.question}_${option}`}
              >
                <button
                  css={radioButton(option === props.selectedOption)}
                  type="button"
                  onClick={() => props.onOptionClick(option)}
                >
                  <div
                    css={radioButtonInner(option === props.selectedOption)}
                  ></div>
                </button>
                {option}
              </div>
            )
          })}
        </div>
      </div>
      <p>{'※単一回答 (必須)'}</p>
    </section>
  )
}

const section = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: color.brack333;
  p {
    font-size: 1.1rem;
    font-weight: 600;
  }
`

const form = css`
  height: 100%;
  padding: 20px;
  border: 1px dashed rgba(51, 51, 51, 0.4);
  border-radius: 12px;
  h3 {
    padding-bottom: 16px;
    font-size: 1.2rem;
    font-weight: 600;
  }
  ${mq.midSHM} {
    width: 100%;
    padding: 16px;
    h3 {
      font-size: 1.4rem;
    }
  }
`

const optionItems = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const radioButtonWithText = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  height: 100%;

  font-size: 1.2rem;
  font-weight: 700;
  line-height: 18px;

  ${mq.midSHM} {
    font-size: 1.4rem;
  }
`

const radioButton = (isSelected: boolean) => css`
  position: relative;
  display: flex;
  width: 16px;
  height: 16px;
  border: 1px solid ${isSelected ? color.red1 : '#BBBBBB'};
  border-radius: 50%;
`

const radioButtonInner = (isSelected: boolean) => css`
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  visibility: ${isSelected ? 'visible' : 'hidden'};
  background-color: ${color.red1};
  border-radius: 100%;
`
