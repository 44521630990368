import React from 'react'
import {
  Layout,
  underMid
} from '@/components/lp/scalehack/for-marketing/layout'
import { Meta } from '@/components/lp/scalehack/for-marketing/meta'
import { FeatureSection } from '@/components/lp/scalehack/for-marketing/freelance/featureSection'
import { StepSection } from '@/components/lp/scalehack/for-marketing/freelance/stepSection'
import {
  BannerSection,
  bannerBoldText
} from '@/components/lp/scalehack/for-marketing/freelance/bannerSection'
import { KeyVisual } from '@/components/lp/scalehack/for-marketing/freelance/keyVisual'
import { Header } from '@/components/lp/scalehack/for-marketing/freelance/header'
import { ProblemSection } from '@/components/lp/scalehack/for-marketing/freelance/problemSection'
import {
  MessageSection,
  messageBoldText
} from '@/components/lp/scalehack/for-marketing/messageSection'
import { FloatingActionButton } from '@/components/lp/scalehack/for-marketing/freelance/floatingActionButton'
import { useObserver } from '@/hooks/useObserber'
import { VoiceSection } from '@/components/lp/scalehack/for-marketing/freelance/voiceSection'
import { RegisterMenu } from '@/components/lp/scalehack/for-marketing/freelance/registerForm/registerMenu'
import { useRegisterFormModal } from '@/components/lp/scalehack/for-marketing/freelance/registerForm/regisiterFormModal'

export default () => {
  const { isDisplayed, ref } = useObserver<HTMLElement>('-50% 0px', true, true)
  const { Modal, openModal } = useRegisterFormModal()
  const handleModalOpen = (purpose?: string) => openModal({ purpose: purpose })

  return (
    <Layout>
      <Meta
        title="Scalehack for Marketing :ハイクラス人材が提供するマーケティング支援"
        description='Scalehack for Marketingは、"好条件"なだけでなく、ハイクラスな人材と共創し成長できる新しいフリーランス・複業の案件が見つかるサービスです。人材派遣 / 案件紹介型のサービスとは全く異なる、新しい「チーム体制型」のフリーランス・複業での働き方を提案します。'
        ogUrl="https://relic-holdings.co.jp/group/scalehack/for-marketing-freelance"
      />
      <Header />
      <KeyVisual ref={ref} openModal={handleModalOpen} />
      <FloatingActionButton
        isDisplayed={isDisplayed}
        openModal={handleModalOpen}
      />
      <ProblemSection />
      <MessageSection>
        人材派遣 / 案件紹介型の
        <br css={underMid} />
        サービスとは全く異なる、
        <br />
        <span css={messageBoldText}>
          新しい「チーム体制型」のフリーランス・複業
        </span>
        での働き方を提案します。
      </MessageSection>
      <FeatureSection />
      <VoiceSection />
      <StepSection />
      <BannerSection>
        <span css={bannerBoldText}>
          まずは一度お気軽に
          <br css={underMid} />
          ご登録ください。
        </span>
      </BannerSection>
      <RegisterMenu openModal={handleModalOpen} />
      <Modal />
    </Layout>
  )
}
