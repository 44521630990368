import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { SingleSelectForm } from './singleSelectForm'
import {
  buttons,
  formGroup,
  nextButton,
  smallSingleSelectForms,
  toTopButton
} from './commonCss'

export type WorkConditionFormData = {
  howToWork: string | null
  workArea: string | null
}

type Props = {
  onSubmit: (data: WorkConditionFormData) => void
  onClose: () => void
  onBack: () => void
  disabled?: boolean
  initialData?: {
    howToWork?: string | null
    workArea?: string | null
  }
}

const howToWorks = ['フルリモート希望', '必要に応じて出社可能']
const workAreas = [
  '北海道',
  '東北',
  '関東',
  '東京23区',
  '中部・北信越',
  '関西',
  '九州・沖縄',
  '海外'
]

export const WorkConditionForm = (props: Props) => {
  const { control, handleSubmit, watch } = useForm<WorkConditionFormData>({
    defaultValues: {
      howToWork: props.initialData?.howToWork,
      workArea: props.initialData?.workArea
    }
  })

  const selectedHowToWork = watch('howToWork')
  const selectedWorkArea = watch('workArea')

  const onSubmit = (data: WorkConditionFormData) => {
    props.onSubmit(data)
  }

  return (
    <form css={formGroup} onSubmit={handleSubmit(onSubmit)}>
      <div css={smallSingleSelectForms}>
        <Controller
          name="howToWork"
          control={control}
          render={({ field }) => {
            return (
              <SingleSelectForm
                question="ご希望の働き方は？"
                selectedOption={field.value}
                options={howToWorks}
                onOptionClick={field.onChange}
              />
            )
          }}
        />
        <Controller
          name="workArea"
          control={control}
          render={({ field }) => {
            return (
              <SingleSelectForm
                question="ご希望の勤務エリアは？"
                selectedOption={field.value}
                options={workAreas}
                onOptionClick={field.onChange}
              />
            )
          }}
        />
      </div>
      <div css={buttons}>
        <button
          css={nextButton}
          disabled={props.disabled || !selectedHowToWork || !selectedWorkArea}
        >
          次へ
        </button>
        <button css={toTopButton} type="button" onClick={props.onBack}>
          戻る
        </button>
      </div>
    </form>
  )
}
