import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import cross from '@/images/scalehack/for-marketing/icon-cross-white.svg'
import color from '@/components/lp/scalehack/color'
import { FormSteps } from './formSteps'
import { ExpertisesForm, ExpertisesFormData } from './expertisesForm'
import { RewardForm, RewardFormData } from './rewardForm'
import { WorkTimeForm, WorkTimeFormData } from './workTimeForm'
import { WorkConditionForm, WorkConditionFormData } from './workConditionForm'
import { BasicForm, BasicFormData } from './basicForm'
import { ContactForm, ContactFormData } from './contactForm'
import { Complete } from './complete'
import { mq } from '@/components/media/media'
import { Header } from '@/components/lp/scalehack/for-marketing/freelance/header'
type OpenModalFormData = {
  purpose?: string
}

type FormDatas = OpenModalFormData &
  BasicFormData &
  ExpertisesFormData &
  RewardFormData &
  WorkTimeFormData &
  WorkConditionFormData &
  ContactFormData

type FormData = { [P in keyof FormDatas]?: FormDatas[P] | null }

type Step = {
  title: string[]
  step: number
  child?: React.ReactElement
}

export const useRegisterFormModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [scrollY, setScrollY] = useState(0)
  const [formData, setFormData] = useState<FormData>({})
  const [currentStep, setCurrentStep] = useState(0)
  const [userId, setUserId] = useState<string | null>(null)
  const [innerHeight, setInnerHeight] = useState('')

  const setInnerHeightFromWindow = () => {
    setInnerHeight(`${window.innerHeight}px`)
  }

  const openModal = (data: OpenModalFormData) => {
    const updated: FormData = { ...formData, ...data }
    setIsOpen(true)
    if (Object.keys(formData).length <= 0) return
    setFormData(updated)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const back = () => {
    if (currentStep === 0) {
      closeModal()
    } else {
      setCurrentStep(currentStep - 1)
    }
  }

  const complete = () => {
    setIsOpen(false)
    setFormData({})
    setUserId(null)
  }

  useEffect(() => {
    const fixWindow = () => {
      setCurrentStep(0)
      const y = window.scrollY
      setScrollY(y)
      document.body.style.position = 'fixed'
      document.body.style.top = `-${y}px`
      document.body.style.height = 'auto'
      document.body.style.width = '100%'
      document.body.style.overflow = 'hidden'
    }
    const unfixWindow = () => {
      document.body.style.position = ''
      document.body.style.top = ''
      document.body.style.height = ''
      document.body.style.width = ''
      document.body.style.overflow = 'auto'
      window.scrollTo(0, scrollY)
    }
    setInnerHeightFromWindow()
    window.removeEventListener('resize', setInnerHeightFromWindow)
    window.addEventListener('resize', setInnerHeightFromWindow)
    if (isOpen) {
      window.history.pushState(null, '', window.location.href)
      window.addEventListener('popstate', closeModal)
      fixWindow()
    } else {
      window.removeEventListener('popstate', closeModal)
      unfixWindow()
    }

    return () => {
      window.removeEventListener('resize', setInnerHeightFromWindow)
      window.removeEventListener('popstate', closeModal)
      unfixWindow()
    }
  }, [isOpen])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    ;(async () => {
      const record = {
        purpose: formData.purpose,
        name: formData.name,
        tel: formData.phone,
        mail: formData.email?.toString(),
        year_of_birth: formData.birthYear?.toString(),
        area_of_specialty: formData.expertises?.toString(),
        reward: formData.monthlyReward,
        ご希望の報酬_時給: formData.hourlyReward,
        how_to_work: formData.howToWork,
        area: formData.workArea,
        start_time: formData.workStarts,
        weekly_available_time: formData.workHours,
        work_experience: formData.experiencedCompanies
          ?.map((value) => value.value)
          .toString()
      }

      const kintoneRecord = Object.entries(record).reduce(
        (acc, [key, value]) =>
          value == null ? acc : { ...acc, [key]: { value: value } },
        {}
      )
      try {
        if (userId == null) {
          const resp = await fetch('/.netlify/functions/post-record', {
            method: 'POST',
            body: JSON.stringify(kintoneRecord),
            signal: signal
          })
          const jsonResp = await resp.json()
          setUserId(jsonResp.id)
        } else {
          await fetch('/.netlify/functions/update-record', {
            method: 'POST',
            body: `{"id": "${userId}", "record": ${JSON.stringify(
              kintoneRecord
            )}}`,
            signal: signal
          })
        }
      } catch (e) {
        console.error(e)
      }
    })()
    return () => {
      if (currentStep !== 6) {
        controller.abort()
      }
    }
  }, [formData])

  const handleNext = (data: any) => {
    const updated: FormData = { ...formData, ...data }
    setCurrentStep((prev) => prev + 1)
    setFormData(() => updated)
  }

  const forms: Step[] = [
    {
      title: ['得意職種を教えてください (複数選択可)'],
      step: 1,
      child: (
        <ExpertisesForm
          onSubmit={handleNext}
          onClose={closeModal}
          onBack={back}
          initialData={{ expertises: formData.expertises ?? [] }}
        />
      )
    },
    {
      title: ['ご希望の条件を教えてください'],
      step: 2,
      child: (
        <RewardForm
          onSubmit={handleNext}
          onClose={closeModal}
          onBack={back}
          initialData={{
            monthlyReward: formData.monthlyReward,
            hourlyReward: formData.hourlyReward
          }}
        />
      )
    },
    {
      title: ['ご希望の稼働時期・時間を教えてください'],
      step: 3,
      child: (
        <WorkTimeForm
          onSubmit={handleNext}
          onClose={closeModal}
          onBack={back}
          initialData={{
            workStarts: formData.workStarts,
            workHours: formData.workHours
          }}
        />
      )
    },
    {
      title: ['ご希望の働き方を教えてください'],
      step: 4,
      child: (
        <WorkConditionForm
          onSubmit={handleNext}
          onClose={closeModal}
          onBack={back}
          initialData={{
            workArea: formData.workArea,
            howToWork: formData.howToWork
          }}
        />
      )
    },
    {
      title: ['基本情報を教えてください (必須)'],
      step: 5,
      child: (
        <BasicForm
          onSubmit={handleNext}
          onClose={closeModal}
          onBack={back}
          initialData={{
            name: formData.name,
            birthYear: formData.birthYear,
            experiencedCompanies: formData.experiencedCompanies
          }}
        />
      )
    },
    {
      title: [
        '連絡先をご入力ください。 ',
        '案件情報の送付でご利用させていただきます。(必須)'
      ],
      step: 6,
      child: (
        <ContactForm
          onSubmit={handleNext}
          onClose={closeModal}
          onBack={back}
          initialData={{
            email: formData.email,
            phone: formData.phone
          }}
        />
      )
    },
    {
      title: [],
      step: 7,
      child: <Complete onClose={complete} />
    }
  ]

  const currentForm = forms[currentStep]

  const Modal = () => {
    if (isOpen) {
      return (
        <div css={modal}>
          <div css={header}>
            <Header />
          </div>
          <div css={modalWrapper(innerHeight)}>
            <div css={container}>
              <div css={contents}>
                <div css={titles}>
                  {currentForm.title.map((text, index) => (
                    <p key={index}>{text}</p>
                  ))}
                </div>
                <FormSteps
                  currentStep={currentStep + 1}
                  stepNum={5}
                ></FormSteps>
                {currentForm.child}
              </div>
              <button css={closeButton} type="button" onClick={closeModal} />
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  return { Modal, openModal, closeModal }
}
const header = css`
  display: none;
  ${mq.midSHM} {
    display: block;
  }
`

const modal = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;

  background: ${color.brackOpacity};
  ${mq.midSHM} {
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0);
  }
`

const modalWrapper = (innerHeight: string) => css`
  ${mq.midSHM} {
    display: flex;
    flex-direction: column;
    height: calc(${innerHeight} - 56px);
  }
`

const container = css`
  position: fixed;
  top: min(160px, max(calc(100% - 680px), 24px));
  left: 50%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 90%;
  max-width: 652px;
  max-height: min(calc(100% - 24px), 680px);

  padding: 32px;
  font-family: 'Helvetica', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro W3',
    'Noto Sans JP', sans-serif;

  background: ${color.white};
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);

  transform: translateX(-50%);

  ${mq.midSHM} {
    position: static;
    top: 0;
    left: 0;
    flex-direction: column;
    gap: 24px;
    justify-content: flex-start;

    width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    padding: 0;
    overflow-y: scroll;
    border-radius: 0;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    transform: translateX(0);
  }
`

const contents = css`
  display: flex;
  flex-direction: column;

  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  ${mq.midSHM} {
    width: 100%;
    padding: 24px 0;
  }
`
const titles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  font-size: 2rem;

  font-weight: 600;
  line-height: 30px;

  text-align: center;
`

const closeButton = css`
  position: absolute;
  top: -24px;

  left: calc(100% - 24px);
  width: 48px;
  height: 48px;
  padding: 12px;

  background: ${color.brack333};
  border-radius: 99px;

  &::after {
    position: absolute;
    top: 50%;
    width: 24px;
    height: 24px;
    content: '';
    background: no-repeat 50% / contain;
    background-image: url(${cross});
    transform: translateY(-50%);
  }
  ${mq.midSHM} {
    display: none;
  }
`
