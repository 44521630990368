import React from 'react'
import { MultiSelectForm } from './multiSelectForm'
import { Controller, useForm } from 'react-hook-form'
import strategy from '@/images/scalehack/for-marketing/freelance/expertises/strategy.svg'
import teamBuilding from '@/images/scalehack/for-marketing/freelance/expertises/team-building.svg'
import brandImage from '@/images/scalehack/for-marketing/freelance/expertises/brand-image.svg'
import programming from '@/images/scalehack/for-marketing/freelance/expertises/programming.svg'
import analytics from '@/images/scalehack/for-marketing/freelance/expertises/analytics.svg'
import bullhorn from '@/images/scalehack/for-marketing/freelance/expertises/bullhorn.svg'
import ads from '@/images/scalehack/for-marketing/freelance/expertises/ads.svg'
import customers from '@/images/scalehack/for-marketing/freelance/expertises/customers.svg'
import setting from '@/images/scalehack/for-marketing/freelance/expertises/settings.svg'
import plan from '@/images/scalehack/for-marketing/freelance/expertises/plan.svg'
import project from '@/images/scalehack/for-marketing/freelance/expertises/project.svg'
import seo from '@/images/scalehack/for-marketing/freelance/expertises/seo.svg'
import socialMedia from '@/images/scalehack/for-marketing/freelance/expertises/social-media.svg'
import crm from '@/images/scalehack/for-marketing/freelance/expertises/crm.svg'
import ux from '@/images/scalehack/for-marketing/freelance/expertises/ux.svg'
import { buttons, formGroup, nextButton, toTopButton } from './commonCss'

const expertises = [
  {
    name: '全体戦略立案',
    src: strategy
  },
  {
    name: 'DX戦略立案',
    src: setting
  },
  {
    name: '組織開発',
    src: teamBuilding
  },
  {
    name: '事業計画立案',
    src: plan
  },
  {
    name: 'ブランド戦略',
    src: brandImage
  },
  {
    name: 'プロジェクトマネジメント',
    src: project
  },
  {
    name: 'Webサイト構築・改善',
    src: programming
  },
  {
    name: 'SEO 戦略設計',
    src: seo
  },
  {
    name: 'MAツール 導入・運用',
    src: analytics
  },
  {
    name: 'SNS運用',
    src: socialMedia
  },
  {
    name: 'オウンド メディア構築',
    src: bullhorn
  },
  {
    name: 'CRM',
    src: crm
  },
  {
    name: '広告運用',
    src: ads
  },
  {
    name: 'UX/UI改善',
    src: ux
  },
  {
    name: 'カスタマーサクセス',
    src: customers
  }
]

export type ExpertisesFormData = {
  expertises: string[]
}

type Props = {
  onSubmit: (data: ExpertisesFormData) => void
  onClose: () => void
  onBack: () => void
  disabled?: boolean
  initialData?: {
    expertises?: string[] | null
  }
}

export const ExpertisesForm = (props: Props) => {
  const { control, watch, handleSubmit } = useForm<ExpertisesFormData>({
    defaultValues: {
      expertises: props.initialData?.expertises ?? []
    }
  })

  const selected = watch('expertises')

  const onSubmit = (data: ExpertisesFormData) => {
    props.onSubmit(data)
  }

  return (
    <form css={formGroup} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="expertises"
        control={control}
        render={({ field }) => {
          const onChange = (name: string) => {
            if (field.value.includes(name)) {
              field.onChange(field.value.filter((value) => value != name))
            } else {
              field.onChange([...field.value, name])
            }
          }

          return (
            <MultiSelectForm
              options={expertises}
              selectedOptions={field.value}
              onOptionClick={onChange}
            />
          )
        }}
      />
      <div css={buttons}>
        <button
          css={nextButton}
          disabled={props.disabled || selected.length === 0}
        >
          次へ
        </button>
        <button css={toTopButton} type="button" onClick={props.onBack}>
          戻る
        </button>
      </div>
    </form>
  )
}
