import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { SingleSelectForm } from './singleSelectForm'
import {
  buttons,
  formGroup,
  nextButton,
  singleSelectForms,
  toTopButton
} from './commonCss'

export type WorkTimeFormData = {
  workStarts: string | null
  workHours: string | null
}

type Props = {
  onSubmit: (data: WorkTimeFormData) => void
  onClose: () => void
  onBack: () => void
  disabled?: boolean
  initialData?: {
    workStarts?: string | null
    workHours?: string | null
  }
}

const workStarts = [
  'なるべく早く',
  '1ヶ月以内',
  '2ヶ月以内',
  '3ヶ月以内',
  '未定'
]
const workHours = ['〜10時間', '10〜20時間', '20〜30時間', '30〜40時間']

export const WorkTimeForm = (props: Props) => {
  const { control, handleSubmit, watch } = useForm<WorkTimeFormData>({
    defaultValues: {
      workStarts: props.initialData?.workStarts,
      workHours: props.initialData?.workHours
    }
  })
  const selectedWorkStarts = watch('workStarts')
  const selectedWorkHours = watch('workHours')

  const onSubmit = (data: WorkTimeFormData) => {
    props.onSubmit(data)
  }

  return (
    <form css={formGroup} onSubmit={handleSubmit(onSubmit)}>
      <div css={singleSelectForms}>
        <Controller
          name="workStarts"
          control={control}
          render={({ field }) => {
            return (
              <SingleSelectForm
                question="ご希望の稼働開始時期は？"
                selectedOption={field.value}
                options={workStarts}
                onOptionClick={field.onChange}
              />
            )
          }}
        />
        <Controller
          name="workHours"
          control={control}
          render={({ field }) => {
            return (
              <SingleSelectForm
                question="週の稼働可能時間の目安は？"
                selectedOption={field.value}
                options={workHours}
                onOptionClick={field.onChange}
              />
            )
          }}
        />
      </div>
      <div css={buttons}>
        <button
          css={nextButton}
          disabled={props.disabled || !selectedWorkStarts || !selectedWorkHours}
        >
          次へ
        </button>
        <button css={toTopButton} type="button" onClick={props.onBack}>
          戻る
        </button>
      </div>
    </form>
  )
}
