import React from 'react'
import { css } from '@emotion/react'
import color from '@/components/lp/scalehack/color'
import thankYou from '@/images/scalehack/for-marketing/freelance/thank-you.svg'
import { mq } from '@/components/media/media'
import { toTopButton } from './commonCss'

type Props = {
  onClose: () => void
}

export const Complete = (props: Props) => {
  return (
    <div css={complete}>
      <div css={contents}>
        <div css={messages}>
          <p>ありがとうございます。</p>
          <p>
            案件についての情報を入力いただいたご連絡先に
            お送りしますのでお待ちください。
          </p>
        </div>
        <img css={img} alt="" src={thankYou} />
      </div>
      <button css={button} onClick={props.onClose}>
        閉じる
      </button>
      <button css={toTopButton} onClick={props.onClose}>
        TOPへ戻る
      </button>
    </div>
  )
}

const complete = css`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  ${mq.midSHM} {
    gap: 120px;
  }
`

const contents = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
`

const messages = css`
  display: flex;
  flex-direction: column;
  max-width: 352px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`

const img = css`
  width: 48px;
  height: 48px;
`

const button = css`
  width: 172px;
  padding: 8px 24px;
  font-weight: 600;
  line-height: 21px;

  color: ${color.white};

  text-align: center;
  text-align: center;

  background: ${color.brack333};
  border-radius: 99px;
  ${mq.midSHM} {
    display: none;
  }
`
