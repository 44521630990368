import color from '@/components/lp/scalehack/color'
import { mq } from '@/components/media/media'
import { css } from '@emotion/react'

export const nextButton = css`
  box-sizing: border-box;
  gap: 10px;

  min-width: 140px;
  height: 37px;

  padding: 8px 24px;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${color.white};

  text-align: center;

  background: ${color.red1};
  border-radius: 99px;

  &:hover {
    opacity: 0.85;
  }

  &:disabled {
    opacity: 0.7;
  }
  ${mq.midSHM} {
    width: 240px;
    height: 45px;
    padding: 12px 24px;
  }
`

export const singleSelectForms = css`
  display: flex;
  flex-direction: row;
  gap: 24px;
  ${mq.midSHM} {
    flex-direction: column;
    gap: 32px;
    width: 100%;
  }
`
export const smallSingleSelectForms = css`
  display: flex;
  flex-direction: row;
  gap: 64px;
  ${mq.midSHM} {
    flex-direction: column;
    gap: 32px;
    width: 100%;
  }
`

export const formGroup = css`
  display: flex;
  flex-direction: column;

  gap: 40px;
  align-items: center;
  justify-content: flex-start;
  ${mq.midSHM} {
    width: 100%;
    padding: 0 20px;
  }
`

export const errorText = css`
  margin-top: 4px;
  font-size: 16px;
  color: ${color.red2};
`

export const toTopButton = css`
  display: none;
  padding: 8px 24px;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 21px;

  text-align: center;
  text-align: center;

  background: ${color.white};

  border: 1px solid ${color.brack333};
  border-radius: 99px;

  ${mq.midSHM} {
    display: block;
    width: 240px;
  }
`

export const buttons = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`