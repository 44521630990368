import pic1 from '@/images/scalehack/for-marketing/freelance/project/project_img_1.jpg'
import pic2 from '@/images/scalehack/for-marketing/freelance/project/project_img_2.jpg'
import pic3 from '@/images/scalehack/for-marketing/freelance/project/project_img_3.jpg'
import pic4 from '@/images/scalehack/for-marketing/freelance/project/project_img_4.jpg'

export type Project = {
  company: string
  title: string
  img: string
  detail: {
    reward: string
    workingTime: string
    workStyle: string
  }
}

export const ProjectList: Project[] = [
  {
    company: '大手通信会社',
    title: 'メタバース事業のPR戦略立案',
    img: pic1,
    detail: {
      reward: '50~80万円',
      workingTime: '60h/月 程度',
      workStyle: 'フルリモート'
    }
  },
  {
    company: 'HR領域・スタートアップ',
    title: 'SaaSプロダクトのマーケティング企画',
    img: pic2,
    detail: {
      reward: '30~50万円',
      workingTime: '40h/月 程度',
      workStyle: 'フルリモート'
    }
  },
  {
    company: '大手フィットネス運営会社',
    title: 'SEM/SEO戦略立案・運用',
    img: pic3,
    detail: {
      reward: '20~30万円',
      workingTime: '50h/月 程度',
      workStyle: 'フルリモート'
    }
  },
  {
    company: 'シリーズB・スタートアップ',
    title: '動画広告サービスのLP制作',
    img: pic4,
    detail: {
      reward: '15~20万円',
      workingTime: '40h/月 程度',
      workStyle: 'フルリモート'
    }
  }
]
