import React from 'react'
import { css } from '@emotion/react'
import color from '@/components/lp/scalehack/color'
import { mq } from '@/components/media/media'

type Props = {
  isDisplayed: boolean
  openModal: () => void
}

export const FloatingActionButton = (props: Props) => {
  const { isDisplayed } = props

  return (
    <div css={container(!isDisplayed)}>
      <div css={buttonWrapper}>
        <div css={headline}>
          <p>
            簡単<span css={headlineNumText}>60</span>秒
            <span css={headlineMidiumText}>で</span>
            <span css={headlineBigText}>無料登録！</span>
          </p>
        </div>
        <button css={button} onClick={props.openModal}>
          登録する
        </button>
      </div>
    </div>
  )
}

const container = (isDisplayed: boolean) => css`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: none;
  width: 100%;
  padding: 12px 35px;
  background-color: ${color.red1};

  ${mq.midSHM} {
    display: block;
  }

  ${isDisplayed
    ? css`
        visibility: visible;
        opacity: 1;
        transition: transform 0.4s ease;
        transform: translateY(0);
      `
    : css`
        visibility: hidden;
        opacity: 0;
        transition: transform 0.4s ease, opacity 0s 0.4s, visibility 0s 0.4s;
        transform: translateY(100%);
      `}
`

const headlineNumText = css`
  font-family: din-2014, sans-serif;
  font-size: 2.4rem;
`

const headlineBigText = css`
  font-size: 1.5rem;
`

const headlineMidiumText = css`
  font-size: 1.2rem;
`

const buttonWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
`

const headline = css`
  font-size: 1.1rem;

  font-weight: 600;
  color: ${color.white};
  text-align: center;
`

const button = css`
  ${mq.midSHM} {
    width: 100%;
    min-width: 140px;
    padding: 12px 20px;
    font-size: 1.6rem;
    font-weight: 600;
    color: ${color.red1};
    text-align: center;
    background: ${color.white};
    border: 1px solid #e4002b;
    border-radius: 99px;
  }
`
