import React from 'react'
import { css } from '@emotion/react'
import RightArrow from '@/images/scalehack/for-marketing/freelance/icon-right-arrow.svg'
import color from '@/components/lp/scalehack/color'
import { mq } from '@/components/media/media'

type Props = {
  openModal: (purpose: string) => void
}

export const RegisterButton = (props: Props) => {
  return (
    <div css={container}>
      <p>ご状況はどちらに近いですか？</p>
      <button
        css={registerButton}
        onClick={() => props.openModal('近いうちに案件獲得したい')}
      >
        近いうちに案件獲得したい
        <div>
          <img src={RightArrow} alt="" />
        </div>
      </button>
      <button
        css={registerButton}
        onClick={() => props.openModal('今は情報収集したい')}
      >
        今は情報収集したい
        <div>
          <img src={RightArrow} alt="" />
        </div>
      </button>
    </div>
  )
}

const registerButton = css`
  display: flex;

  flex: none;
  flex-direction: row;
  flex-grow: 0;
  gap: 10px;
  align-items: center;
  align-items: center;
  justify-content: space-between;

  width: 266px;
  height: 64px;
  padding: 20px;
  font-size: 1.6rem;

  font-weight: 600;

  color: #e4002b;

  background: #ffffff;
  border-radius: 4px;
  &:hover {
    opacity: 0.85;
  }

  &:disabled {
    opacity: 0.5;
  }

  ${mq.midSHM} {
    box-shadow: 0px 4px 12px rgba(105, 18, 34, 0.44);
  }
`

const container = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 1.2rem;
    font-weight: 600;

    color: ${color.white};
  }

`
