import React, { ReactNode } from 'react'
import { css } from '@emotion/react'
import color from '@/components/lp/scalehack/color'
import { Button } from '@/components/lp/scalehack/for-marketing/button'
import { mq } from '@/components/media/media'

type Props = {
  children: ReactNode
}

export const BannerSection = (props: Props) => {
  const { children } = props

  return (
    <section css={section}>
      <div css={sectionInner}>
        <p css={text}>{children}</p>
        <div css={buttonContainer}>
          <Button
            css={button}
            color="white"
            to="/group/scalehack/for-marketing-freelance/apply"
          >
            登録する
          </Button>
        </div>
      </div>
    </section>
  )
}

const section = css`
  display: flex;
  justify-content: center;
  padding: 124px 0;
  color: ${color.white};
  background: linear-gradient(
    99.81deg,
    ${color.orange8} -0.55%,
    ${color.orange9} 100%
  );

  ${mq.midSHM} {
    padding: 40px 20px;
  }
`

const sectionInner = css`
  width: 100%;
  max-width: 1120px;
  margin: 0 16px;
`

const text = css`
  font-size: 2.8rem;
  font-weight: 300;
  color: ${color.white};
  text-align: center;

  ${mq.midSHM} {
    font-size: 1.6rem;
  }
`

export const bannerBoldText = css`
  font-weight: 600;
`

const buttonContainer = css`
  display: flex;
  justify-content: center;
  margin-top: 38px;

  ${mq.midSHM} {
    flex-direction: column;
    align-items: center;
  }
`

const button = css`
  width: 240px;
  ${mq.midSHM} {
    width: 152px;
  }
`
