import React, { useState } from 'react'
import { css } from '@emotion/react'
import cross from '@/images/scalehack/for-marketing/icon-cross.svg'
import color from '@/components/lp/scalehack/color'
import { RegisterButton } from './registerButton'
import { mq } from '@/components/media/media'

type Props = {
  openModal: (purpose: string) => void
}

export const RegisterMenu = (props: Props) => {
  const [menuIsVisible, setMenuIsVisible] = useState(true)

  const onMenuClose = () => {
    setMenuIsVisible(false)
  }

  return (
    <section>
      <div css={container(menuIsVisible)}>
        <div css={headline}>
          <p>
            簡単<span css={headlineNumText}>60</span>秒
            <span css={headlineMidiumText}>で</span>
            <span css={headlineBigText}>無料登録！</span>
          </p>
        </div>
        <button css={closeButton} onClick={onMenuClose} />
        <RegisterButton openModal={props.openModal} />
      </div>
    </section>
  )
}

const container = (isVisible: boolean) => css`
  position: fixed;
  bottom: 40px;
  left: calc(100vw - 354px);
  z-index: 100;
  display: 'block';
  width: 314px;
  height: 242px;
  padding: 44px 24px 28px 24px;
  font-family: 'Helvetica', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro W3',
    'Noto Sans JP', sans-serif;
  visibility: ${isVisible ? 'visible' : 'hidden'};
  background: ${color.red1};
  border-radius: 20px;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.16);

  ${mq.midSHM} {
    display: none;
  }
`
const headline = css`
  position: absolute;
  top: -20px;
  left: 67px;
  width: 195px;
  height: 45px;
  text-align: center;

  background: ${color.white};
  border: 3px solid ${color.red1};
  border-radius: 99px;

  p {
    font-size: 1.1rem;
    font-weight: 600;
    color: ${color.red1};
  }
`

const headlineNumText = css`
  font-family: din-2014, sans-serif;
  font-size: 2.4rem;
`

const headlineBigText = css`
  font-size: 1.5rem;
`

const headlineMidiumText = css`
  font-size: 1.2rem;
`

const closeButton = css`

  position: absolute;
  top: -18px;
  left: 296px;
  width: 36px;
  height: 36px;
  padding: 6px;

  background: ${color.white};
  border-radius: 99px;

  &::after {
    position: absolute;
    top: 50%;
    width: 24px;
    height: 24px;
    content: '';
    background: no-repeat 50% / contain;
    background-image: url(${cross});
    transform: translateY(-50%);
  }
`

