import React from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { buttons, formGroup, nextButton, toTopButton } from './commonCss'
import downArrow from '@/images/scalehack/for-marketing/freelance/icon-down-arrow.svg'
import { css } from '@emotion/react'
import color from '@/components/lp/scalehack/color'
import cross from '@/images/scalehack/for-marketing/icon-cross-gray.svg'
import plus from '@/images/scalehack/for-marketing/freelance/icon-plus.svg'
import { mq } from '@/components/media/media'

type ExperiencedCompany = {
  value: string
}

export type BasicFormData = {
  name: string
  birthYear: string
  experiencedCompanies: ExperiencedCompany[]
}

type Props = {
  onSubmit: (data: BasicFormData) => void
  onClose: () => void
  onBack: () => void
  disabled?: boolean
  initialData?: {
    name?: string | null
    birthYear?: string | null
    experiencedCompanies?: ExperiencedCompany[] | null
  }
}

const birthYears = Array(36)
  .fill(0)
  .map((_, i) => {
    return `${i + 1968}`
  })

export const BasicForm = (props: Props) => {
  const { register, handleSubmit, control, formState } = useForm<BasicFormData>(
    {
      defaultValues: {
        name: props.initialData?.name ?? '',
        birthYear: props.initialData?.birthYear ?? '',
        experiencedCompanies: props.initialData?.experiencedCompanies?.map(
          (company) => {
            return {
              value: company.value
            }
          }
        ) ?? [
          {
            value: ''
          }
        ]
      },
      mode: 'onChange'
    }
  )

  const { fields, append, remove } = useFieldArray({
    name: 'experiencedCompanies',
    control
  })

  const onSubmit = (data: BasicFormData) => {
    props.onSubmit(data)
  }

  return (
    <form css={formGroup} onSubmit={handleSubmit(onSubmit)}>
      <div css={forms}>
        <div css={formContainer}>
          <label css={label}>
            <span css={labelTitle}>氏名</span>
            <input
              type="text"
              css={formInput('174px')}
              placeholder="例）山田太郎"
              {...register('name', {
                required: '必須項目に入力してください。'
              })}
            />
          </label>
        </div>
        <div css={formContainer}>
          <label css={label}>
            <span css={labelTitle}>生まれ年</span>
            <div css={selectContainer}>
              <select
                required
                css={formSelect}
                {...register('birthYear', {
                  required: '必須項目に入力してください。'
                })}
              >
                <option value="">例）1992</option>
                {birthYears.map((option) => {
                  return (
                    <option css={formInput('93px')} value={option} key={option}>
                      {option}
                    </option>
                  )
                }, {})}
              </select>
            </div>
          </label>
        </div>
        <div css={formContainer}>
          <label css={label}>
            <span css={labelTitle}>経験企業</span>
            <div css={multiInputConatiner}>
              {fields.map((field, i) => {
                return (
                  <div css={companyForm} key={field.id}>
                    <input
                      css={companyFormInput}
                      type="text"
                      placeholder="例）株式会社〇〇"
                      {...register(
                        `experiencedCompanies.${i}.value`,
                        i === 0
                          ? {
                              required: '必須項目に入力してください。'
                            }
                          : {}
                      )}
                    />
                    <button
                      css={formDeleteButton}
                      disabled={i === 0}
                      onClick={() => remove(i)}
                    />
                  </div>
                )
              })}
              <button
                css={addButton}
                onClick={() => append({ value: '' })}
                type="button"
              >
                経歴を追加する
              </button>
            </div>
          </label>
        </div>
      </div>
      <div css={buttons}>
        <button css={nextButton} disabled={!formState.isValid}>
          次へ
        </button>
        <button css={toTopButton} type="button" onClick={props.onBack}>
          戻る
        </button>
      </div>
    </form>
  )
}

const forms = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 40px;
  color: color.brack333;
  border: 1px dashed #333333;
  border-radius: 12px;
  p {
    font-size: 1.1rem;
    font-weight: 600;
  }
  ${mq.midSHM} {
    flex-direction: column;
    width: 100%;
    padding: 16px;
  }
`

const formContainer = css`
  position: relative;
`

const formInput = (width: string) => css`
  gap: 10px;

  width: ${width};

  max-width: ${width};
  padding: 12px 8px;

  font-size: 1.6rem;
  line-height: 18px;
  background-color: ${color.white};

  border: 1px solid #acacac;
  border-radius: 4px;

  ::placeholder {
    opacity: 0.4;
  }

  ${mq.midSHM} {
    flex: 1;
    max-width: 100%;
    margin-right: 20px;
  }
`

const companyFormInput = css`
  gap: 10px;

  width: 260px;

  max-width: 260px;
  padding: 12px 8px;

  font-size: 1.6rem;
  line-height: 18px;
  background-color: ${color.white};
  border: 1px solid #acacac;
  border-radius: 4px;

  ::placeholder {
    opacity: 0.4;
  }

  ${mq.midSHM} {
    width: 100%;
    max-width: 100%;
  }
`

const companyForm = css`
  display: flex;
  flex-direction: row;

  align-items: center;
`

const formDeleteButton = css`
  width: 20px;
  height: 20px;
  margin-left: 4px;
  background-image: url(${cross});
  background-repeat: no-repeat;
  background-size: cover;
  &:disabled {
    visibility: hidden;
  }
  ${mq.midSHM} {
    margin-left: 9px;
  }
`

const formSelect = css`
  position: relative;

  width: 100%;
  height: 100%;
  padding: 12px 8px;
  background: none;

  &:invalid {
    opacity: 0.4;
  }
`

const selectContainer = css`
  position: relative;

  width: 109px;
  max-width: 109px;

  font-size: 1.4rem;
  line-height: 18px;
  background: ${color.white};

  border: 1px solid #acacac;
  border-radius: 4px;

  &::after {
    position: absolute;
    top: 13.5px;
    right: 8px;
    bottom: 13.5px;
    width: 16px;
    height: 16px;
    pointer-events: none;
    content: '';
    background-image: url(${downArrow});
    background-repeat: no-repeat;
    background-size: cover;
  }
`

const label = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

const labelTitle = css`

  width: 64px;
  padding-top: 9px;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 18px;

  color: #333333;
  white-space: nowrap;
  ${mq.midSHM} {
    width: 80px;
    font-size: 1.4rem;
    line-height: 21px;
  }
`
const multiInputConatiner = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${mq.midSHM} {
    flex: 1;
    max-width: 100%;
  }
`

const addButton = css`
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 12px;

  font-weight: 700;
  line-height: 18px;

  &::before {
    width: 20px;
    height: 20px;
    content: '';
    background-image: url(${plus});
    background-repeat: no-repeat;
    background-size: cover;
  }
`
