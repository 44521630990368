import React from 'react'
import { css } from '@emotion/react'
import color from '@/components/lp/scalehack/color'
import { HugeTitle } from '@/components/lp/scalehack/for-marketing/hugeTitle'
import right_arrow from '@/images/scalehack/for-marketing/icon-right-arrow.svg'
import { mq } from '@/components/media/media'
import { useObserver } from '@/hooks/useObserber'

export const StepSection = () => {
  const { isDisplayed, ref } = useObserver<HTMLElement>(
    '-80% 0px -20%',
    false,
    false
  )

  return (
    <section css={section} ref={ref}>
      <div css={inner}>
        <HugeTitle
          jpTitleText="稼働開始までの流れ"
          enTitleText="STEP"
          isDisplayed={isDisplayed}
        />
        <ul css={list}>
          <li css={listItem}>
            <p css={listTitle}>STEP</p>
            <p css={listNumber}>1</p>
            <p css={listText}>{`必要情報の入力\n書類のご提出`}</p>
          </li>
          <li css={listItem}>
            <p css={listTitle}>STEP</p>
            <p css={listNumber}>2</p>
            <p css={listText}>{`面談による\n希望ヒアリング`}</p>
          </li>
          <li css={listItem}>
            <p css={listTitle}>STEP</p>
            <p css={listNumber}>3</p>
            <p css={listText}>{`業務アサインの\n打診`}</p>
          </li>
          <li css={listItem}>
            <p css={listTitle}>STEP</p>
            <p css={listNumber}>4</p>
            <p css={listText}>稼働開始</p>
          </li>
          <li css={listItemSpacer} />
          <li css={listItemSpacer} />
        </ul>
      </div>
    </section>
  )
}

const section = css`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  margin-bottom: 160px;

  ${mq.midSHM} {
    padding-top: 80px;
    margin-bottom: 80px;
  }
`

const inner = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 260px);
  max-width: 1120px;

  ${mq.midSHM} {
    width: calc(100% - 40px);
    max-width: 700px;
  }
`

const list = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 88px 0 0 -52px;

  ${mq.midSHM} {
    margin: 6px 0 0 -20px;
  }
`

const listItem = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 224px;
  height: 224px;
  padding-top: 42px;
  margin-left: 52px;
  background-color: ${color.orange7};
  border-radius: 10em;

  ${mq.midSHM} {
    width: 148px;
    height: 148px;
    padding-top: 34px;
    margin: 32px 0 0 20px;
  }

  &:not(:first-of-type):before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(-52px / 2 + -20px / 2);
    width: 20px;
    height: 20px;
    margin: auto 0;
    content: '';
    background: url(${right_arrow});

    ${mq.midSHM} {
      content: unset;
    }
  }
`

const listItemSpacer = css`
  @media screen and (max-width: 683px) {
    width: calc(148px + 20px);
  }
`

const listTitle = css`
  font-family: Arial, sans-serif;
  font-size: 1.2rem;
  font-weight: 400;

  ${mq.midSHM} {
    margin-bottom: -6px;
    transform: scale(0.8);
  }
`

const listNumber = css`
  font-family: din-2014, sans-serif;
  font-size: 2.8rem;
  font-weight: 700;

  ${mq.midSHM} {
    font-size: 1.6rem;
  }
`

const listText = css`
  margin-top: 12px;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  white-space: pre-wrap;

  ${mq.midSHM} {
    margin-top: 8px;
    font-size: 1.2rem;
  }
`
