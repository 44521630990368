import React from 'react'
import { Project } from '@/constants/scalehack/projectList'
import Slider, { Settings } from 'react-slick'
import { css } from '@emotion/react'
import color from '@/components/lp/scalehack/color'

type Props = {
  projects: Project[]
  className?: string
}

const settings: Settings = {
  infinite: false,
  speed: 750,
  slidesToShow: 4,
  autoplay: false,
  swipeToSlide: true,
  arrows: false,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 1
      }
    }
  ]
}

export const ProjectCardSlide = (props: Props) => {
  const { projects, className } = props

  return (
    <Slider {...settings} css={slider} className={className}>
      {projects.map((project, i) => {
        return (
          <div key={i} css={slide}>
            <div css={[slideInner]}>
              <div css={company}>{project.company}</div>
              <div css={title}>{project.title}</div>
              <div css={picture}>
                <img src={project.img} alt="" />
              </div>
              <dl css={data}>
                <dt css={dataTitle}>報酬</dt>
                <dd css={dataDetail}>{project.detail.reward}</dd>
              </dl>
              <dl css={data}>
                <dt css={dataTitle}>稼働時間</dt>
                <dd css={dataDetail}>{project.detail.workingTime}</dd>
              </dl>
              <dl css={data}>
                <dt css={dataTitle}>働き方</dt>
                <dd css={dataDetail}>{project.detail.workStyle}</dd>
              </dl>
            </div>
          </div>
        )
      })}
    </Slider>
  )
}

const slider = css`
  margin: 0 -10px;
  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    float: none;
    height: auto;
    > div {
      height: 100%;
    }
  }
`

const slide = css`
  height: 100%;
  padding: 17px 10px;
`

const slideInner = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  background-color: ${color.white};
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
`

const company = css`
  width: fit-content;
  padding: 2px 6px;
  font-size: 1rem;
  font-weight: 600;
  background-color: ${color.grayF0};
  border-radius: 30px;
`

const title = css`
  flex: 1 0 auto;
  margin-top: 10px;
  font-size: 1.4rem;
`

const picture = css`
  margin-top: 12px;
`

const data = css`
  display: flex;
  gap: 10px 9px;
  margin-top: 12px;
  font-size: 1.2rem;
  & + & {
    margin-top: 7px;
  }
`

const dataTitle = css`
  flex-shrink: 0;
  min-width: 56px;
  padding: 2px 4px;
  font-weight: 600;
  color: ${color.white};
  text-align: center;
  background-color: ${color.pink1};
  border-radius: 30px;
`

const dataDetail = css`
  display: flex;
  align-items: center;
  min-height: 22px;
`
