import React from 'react'
import { css } from '@emotion/react'
import { HugeTitle } from '@/components/lp/scalehack/for-marketing/hugeTitle'
import { mq } from '@/components/media/media'
import { useObserver } from '@/hooks/useObserber'
import { VoiceSlide } from '@/components/lp/scalehack/for-marketing/freelance/voiceSlide'
import { VoiceList } from '@/constants/scalehack/voiceList'

export const VoiceSection = () => {
  const { isDisplayed, ref } = useObserver<HTMLElement>(
    '-80% 0px -20%',
    false,
    false
  )

  return (
    <section css={section} ref={ref}>
      <div css={sectionInner}>
        <HugeTitle
          jpTitleText="ご登録者さまの声"
          enTitleText="VOICE"
          isDisplayed={isDisplayed}
        />
        <div css={slideWrapper}>
          <VoiceSlide voices={VoiceList} />
        </div>
      </div>
    </section>
  )
}

const section = css`
  display: flex;
  justify-content: center;
  margin: 110px 0 0;
  overflow: hidden;

  ${mq.midSHM} {
    margin: 85px 0 0;
  }
`

const sectionInner = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 260px);
  max-width: 1152px;

  ${mq.midSHM} {
    width: calc(100% - 40px);
    max-width: 700px;
  }
`

const slideWrapper = css`
  width: 90%;
  max-width: 804px;
  margin: 85px auto 0;

  ${mq.midSHM} {
    width: 100%;
    margin: 45px -17px 0 -17px;
  }
`
